const modalState = reactive({
  isOpen: false,
})

const showModal = () => Object.assign(modalState, { isOpen: true })
const hideModal = () => modalState.isOpen = false

export {
  hideModal,
  showModal,
  modalState,
}
